/* Company select controls */
const toggleCompanySelect = (e) => {
    const isOpen = e.target.classList.contains('open');
    if (isOpen) {
        e.target.classList.remove('open');
    } else {
        e.target.classList.add('open')
    }
}

const companySelectButton = document.getElementById('company-select-btn');
// only need to add event listener if user has access to multiple companies
if (companySelectButton && companySelectButton.classList?.contains('pl-has-subnav')) {
    companySelectButton.addEventListener('click', (el) => {
        toggleCompanySelect(el);
    })
}

const switchCompany = (el) => {
    const companyGroupId = el.getAttribute('data-company-id');
    window.location.href = `/accounts/v1/me/activate-group/?group=${companyGroupId.toString()}&next=/&errnext=/login`;
}

const companySelectOptions = document.querySelectorAll('#pw-company-select');
companySelectOptions.forEach(el => {
    el.addEventListener('click', (e) => {
        switchCompany(el)
    });
});

const companyNameMenu = document.querySelector('.pl-sub-nav.pw-pl-sub-nav');
// Hide dropdown if clicking outside
document.addEventListener('click', (event) => {
    if (!companySelectButton?.contains(event.target) && !companyNameMenu?.contains(event.target)) {
        if (companySelectButton?.classList.contains('open')) {
            companySelectButton.classList.remove('open');
        }
    }
});

